import React from 'react';
import './SolutionDescription.css';
import Fade from 'react-reveal/Fade';
import ImageLoader from '../../../Components/Helpers/ImageLoader';

export default function SolutionDescription() {
    return (
        <div id='how-it-works-frame' className="how-it-works-frame">
            <h2>How it works</h2>
            <div className='hiw-main-container'>
                <div className='hiw-node-1-container'>
                    <div className='hiw-node-media-container-1'>
                        <div className='img-container'>
                            <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/solution-desciprtion-2.png' imgClass='hiw-node-img-1' skeletonH={'100%'} skeletonW={'100%'} skeletonR={'8px'} />
                        </div>
                    </div>
                    <div className='hiw-desc-container-1'>
                        <div className='hiw-desc-container-inner'>
                            <Fade>
                                <div className='hiw-desc-header'>
                                    <h3>Share memories</h3>
                                </div>
                                <p className='hiw-desc-body'>Capture and share your joyful moments with a location, caption, and images.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='hiw-node-2-container'>
                    <div className='hiw-desc-container-2'>
                        <div className='hiw-desc-container-inner'>
                            <Fade>
                                <div className='hiw-desc-header'>
                                    <h3>Build travel history</h3>
                                </div>
                                <p className='hiw-desc-body'>Build history of memories with interactive map and fun stats.</p>
                            </Fade>
                        </div>
                    </div>
                    <div className='hiw-node-media-container-2'>
                        <div className='img-container'>
                            <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/solution-desciprtion-3-2.png' imgClass='hiw-node-img-2' skeletonH={'100%'} skeletonW={'100%'} skeletonR={'8px'} />
                        </div>
                    </div>
                </div>
                <div className='hiw-node-1-container'>
                    <div className='hiw-node-media-container-1'>
                        <div className='img-container'>
                            <ImageLoader src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/main/solution-desciprtion-1.png' imgClass='hiw-node-img-3' skeletonH={'100%'} skeletonW={'100%'} skeletonR={'8px'} />
                        </div>
                    </div>
                    <div className='hiw-desc-container-1'>
                        <div className='hiw-desc-container-inner'>
                            <Fade>
                                <div className='hiw-desc-header'>
                                    <h3>Try new experiences</h3>
                                </div>
                                <p className='hiw-desc-body'>Find experiences you're into and get inspired for new adventures.</p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}